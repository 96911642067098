.main-header {
  padding: 20px 0;
  position: sticky;
  top: 0;
  z-index: 999;
  transition: all 0.15s ease;
}

.main-header .header-top-belt {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: center;
}

.main-header .header-top-belt .logo img {
  width: 200px;
  transition: all 0.22s linear;
}

.main-header .header-top-belt .header-top-belt-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.main-header
  .header-top-belt
  :is(.header-top-belt-right, .header-top-belt-right ul) {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.main-header .header-top-belt .header-top-belt-right ul li {
  display: flex;
  gap: 10px;
}

.main-header .header-top-belt .header-top-belt-right ul li > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  line-height: 1;
}

.main-header .header-top-belt .header-top-belt-right ul li > div > * {
  font-size: 14px;
}

.main-header .header-top-belt .header-top-belt-right ul li > div > span {
  color: var(--light-text-color);
  font-weight: var(--fw-medium);
}

.header-search-wrapper {
  display: inline-block;
  position: relative;
}

.header-search-wrapper .form-control {
  font-size: 14px;
  color: var(--light-text-color);
  border: 1px solid var(--border-black-color);
  background-color: transparent !important;
  padding: 8px 12px;
  border-radius: 35px;
  min-width: 270px;
  line-height: 1;
}

.header-search-wrapper .form-control:focus {
  border-color: var(--theme-body-bg-blue-color);
  /* box-shadow: 0 0 0 .25rem rgb(26, 23, 27, .25); */
  box-shadow: 0 0 0 0.25rem rgb(0, 141, 210, 0.25);
}

.header-search-wrapper .form-control::placeholder {
  color: var(--light-text-color);
}

.header-search-wrapper > i {
  color: var(--light-text-color);
  font-size: 16px;
  position: absolute;
  top: 0;
  right: 12px;
  bottom: 0;
  display: flex;
  align-items: center;
}

.main-header .menu {
  display: flex;
  justify-content: center;
  gap: 45px;
}

.main-header .menu a {
  color: var(--black-text-color);
  font-weight: var(--fw-medium);
  display: inline-block;
}

.main-header .menu a:hover {
  color: var(--theme-body-bg-blue-color);
}

.main-header .cart-menu .MuiBadge-badge {
  background-color: var(--theme-body-bg-blue-color);
  transform: scale(0.9) translate(43%, -43%);
}

@media (max-width: 991px) {
  .main-header .header-top-belt {
    display: flex;
    justify-content: space-between;
  }

  .main-header {
    padding: 15px 0;
  }

  .main-header .header-top-belt .logo img {
    width: 150px;
  }

  .main-header .menu {
    position: fixed;
    top: var(--header-height);
    right: -350px;
    bottom: 0;
    width: 280px;
    background-color: var(--theme-body-bg-white-color);
    box-shadow: 3px 10px 8px rgb(26, 23, 27, 0.85);
    z-index: 999;
    overflow-y: auto;
    transition: all 0.22s linear;
    display: block;
  }

  .main-header .menu li {
    display: block;
  }

  .main-header .menu li a {
    display: block;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
  }

  .overlay {
    display: block;
    position: fixed;
    top: var(--header-height);
    left: 0;
    right: 100%;
    bottom: 0;
    background-color: var(--theme-body-bg-black-color);
    opacity: 0.9;
    z-index: 992;
  }

  .main-header.active-menu .menu {
    right: 0;
  }

  .overlay.active-menu {
    right: 0;
  }

  .main-header .header-search-wrapper {
    display: none;
  }

  .main-header .header-top-belt .header-top-belt-right ul li > div {
    display: none;
  }

  .main-header
    .header-top-belt
    :is(.header-top-belt-right, .header-top-belt-right ul) {
    gap: 10px;
  }

  .main-header .header-top-belt .header-top-belt-right #person-icon {
    width: 32px;
    height: 32px;
  }

  .main-header .header-top-belt .header-top-belt-right #cart-icon {
    width: 28px;
    height: 28px;
  }
}

/* active header  */

.main-header.active {
  background-color: var(--theme-body-bg-white-color);
  box-shadow: 0 3px 8px 0 rgba(26, 23, 27, 0.2);
}

@media (min-width: 992px) {
  .main-header.active {
    padding: 15px 0;
  }

  .main-header .header-top-belt .logo img {
    width: 130px;
  }
}

/* menubar */

.menubar {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  margin-left: 10px;
}

.menubar span {
  display: block;
  width: 100%;
  height: 2.5px;
  background: var(--black-text-color);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

header.active-menu .menubar span:nth-child(1) {
  -webkit-transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 7);
  transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 7);
}

header.active-menu .menubar span:nth-child(2) {
  -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, -8);
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, -8);
}

@media (min-width: 992px) {
  header :is(.menubar) {
    display: none;
  }
}

/* profile menu */

#header-profile-menu .MuiMenu-list {
  width: 170px !important;
  padding: 0;
}

#header-profile-menu .MuiPaper-elevation {
  top: 92px !important;
}

#header-profile-menu .MuiPaper-elevation li {
  padding: 10px 15px;
}

#header-profile-menu .MuiPaper-elevation li:hover {
  background-color: var(--theme-body-bg-blue-color);
  color: var(--white-text-color);
}

#header-profile-menu .MuiPaper-elevation li:hover a {
  color: var(--white-text-color);
}

/* cart menu */

.cart-menu-dropdown {
  position: fixed;
  top: 0;
  right: -35%;
  bottom: 0;
  background-color: var(--theme-body-bg-white-color);
  width: 35%;
  z-index: 999;
  box-shadow: 3px 3px 8px rgba(255, 255, 255, 0.8);
  overflow-y: auto;
  transition: all 0.3s ease;
}

.cart-menu-dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 100%;
  bottom: 0;
  background-color: var(--theme-body-bg-black-color);
  opacity: 0.9;
  z-index: 992;
}

.cart-menu-dropdown-overlay.active {
  right: 0;
}

.cart-menu-dropdown.active {
  right: 0;
}

.cart-menu-dropdown {
  padding: 30px 20px;
}

.cart-menu-dropdown .cart-menu-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--theme-body-bg-blue-color);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.cart-menu-dropdown .cart-menu-foot {
  display: flex;
  justify-content: flex-end;
  /* border-top: 1px solid var(--theme-body-bg-blue-color); */
  padding-top: 15px;
}

.cart-menu-table th {
  font-size: 14px !important;
  text-transform: uppercase;
  white-space: nowrap;
}

.cart-menu-table :is(th, td) {
  font-size: 16px;
  padding: 12px;
  vertical-align: middle;
}

.cart-menu-item {
  display: flex;
  gap: 15px;
}

.cart-menu-item .cart-menu-item-img {
  border: 1px solid var(--border-light-color)!important;
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 5px;
  background-color: var(--theme-body-bg-white-color);
  display: grid;
  place-items: center;
  overflow: hidden;
}

.cart-menu-item .cart-menu-item-img img {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: center;
}

.cart-menu-item .cart-menu-item-content p {
  font-size: 12.5px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.cart-menu-item .cart-menu-item-content p:nth-child(2) {
  color: var(--theme-body-bg-blue-color);
  margin-bottom: 0;
}

@media(max-width: 991px){
  .cart-menu-dropdown {
    right: -300px;
    width: 280px;
  }
}

/* footer */
footer {
  background-color: #1a1a1a;
  color: var(--white-text-color);
}

footer .logo img {
  width: 250px;
}

footer h5 {
  font-size: 18px;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

footer .footer-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

footer .footer-menu li a,
footer .contact-info li a {
  color: var(--white-text-color);
  font-weight: var(--fw-medium);
  opacity: 0.6;
}

footer .footer-menu li a:hover,
footer .contact-info li a:hover {
  opacity: 1;
}

/* footer .copyright { */
/* background-color: #1a1a1a; */
/* } */

footer .contact-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

footer .contact-info li {
  display: flex;
  gap: 15px;
  align-items: center;
}

footer .contact-info li i {
  font-size: 14px;
  border: 1px solid var(--theme-body-bg-white-color);
  width: 35px;
  min-width: 35px;
  height: 35px;
  border-radius: 50px;
  display: grid;
  place-items: center;
}

footer address {
  opacity: 0.6;
}

@media (max-width: 991px) {
  footer .logo img {
    width: 170px;
  }
}
