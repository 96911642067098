@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

:root {
  --theme-body-bg-blue-color: #008dd2;
  --theme-body-bg-white-color: #ffffff;
  --theme-body-bg-black-color: #1a171b;
  --theme-body-bg-red-color: #e51219;
  --theme-body-bg-green-color: #56ca00;
  --theme-body-bg-yellow-color: #fbb900;
  --theme-body-bg-blue-light-color: #e7f4f9;
  --theme-body-bg-blue-dark-color: #d1e9f0;
  --theme-body-bg-light-color: #fafafa;
  --theme-body-bg-light-dark-color: #ececec;

  --black-text-color: var(--theme-body-bg-black-color);
  --white-text-color: var(--theme-body-bg-white-color);
  --light-text-color: #656d74;
  --red-text-color: var(--theme-body-bg-red-color);

  --border-black-color: var(--theme-body-bg-black-color);
  --border-light-color: #dddddda1;

  --header-height: 79.83px;

  --fw-light: 300;
  --fw-medium: 500;
  --fw-bold: 900;

  --section-padding: 40px;

  --heading-1: 32px;
  --heading-2: 28px;

  --large-text-2-fs: 24px;
}

@media (min-width: 992px) {
  :root {
    --heading-1: 42px;
    --heading-2: 32px;

    --section-padding: 75px;

    --large-text-2-fs: 26px;

    --header-height: 117.4px;
  }
}

@media (min-width: 1400px) {
  :root {
    --heading-1: 56px;
    --heading-2: 36px;

    --large-text-2-fs: 28px;

    --section-padding: 100px;
  }
}

body {
  font: var(--fw-light) 16px/1.6 "Manrope", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--fw-bold) !important;
}

:not(p) a {
  text-decoration: none;
  color: var(--black-text-color);
}

.heading-1 {
  font-size: var(--heading-1);
  line-height: 1.2;
}

.heading-2 {
  font-size: var(--heading-2);
}

b {
  font-weight: var(--fw-bold);
}

:is(header, footer) a {
  text-decoration: none;
}

:is(header, footer) ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.text-blue {
  color: var(--theme-body-bg-blue-color);
}

.section-padding {
  padding: var(--section-padding) 0;
}

.small-heading {
  color: var(--red-text-color);
  font-size: 14px;
  font-weight: var(--fw-bold);
  position: relative;
  padding-left: 35px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.small-heading::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  bottom: 0;
  width: 25px;
  height: 3px;
  background-color: var(--theme-body-bg-red-color);
  transform: translateY(-50%);
}

.large-text-1 {
  font-size: 18px;
  font-weight: var(--fw-medium);
}

.large-text-2 {
  font-size: var(--large-text-2-fs);
  font-weight: var(--fw-light);
}

.p-last-0 p:last-child {
  margin-bottom: 0;
}

hr {
  border-color: var(--border-light-color);
  opacity: 1;
}

.btn {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  font-weight: var(--fw-bold);
  padding: 13px 15px;
  line-height: normal;
  border-radius: 10px;
  color: var(--black-text-color);
  background-color: transparent;
  border: 2px solid var(--border-black-color);
  letter-spacing: 0.5px;
  min-width: 160px;
  width: auto;
  text-align: center;
  overflow: hidden;
  transition: all 0.18s ease-in-out;
  z-index: 1;
}

.btn::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 120%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotate(10deg) translate(-1.2em, 110%);
  transform-origin: 0% 100%;
  transition: transform 500ms ease;
  background-color: var(--theme-body-bg-black-color);
}

.btn:hover::after,
.btn:focus::after,
.btn:active::after {
  background-color: var(--theme-body-bg-black-color);
  transform: translate(0) rotate(0);
}

.btn.btn-sm {
  font-size: 14px;
  padding: 10px 12px;
  border-radius: 6px;
}

.btn.btn-black:is(:hover, :focus) {
  color: var(--white-text-color);
  border-color: var(--theme-body-bg-blue-color);
}

.btn.btn-black::after {
  background-color: var(--theme-body-bg-blue-color);
}

.btn-blue,
.btn-blue:hover,
.btn-blue:focus {
  border-color: var(--theme-body-bg-blue-color) !important;
  background-color: var(--theme-body-bg-blue-color) !important;
  color: var(--white-text-color) !important;
}

.btn-blue::after {
  display: none !important;
}

.btn-warning,
.btn-warning:hover,
.btn-warning:focus {
  border-color: var(--theme-body-bg-yellow-color) !important;
  background-color: var(--theme-body-bg-yellow-color) !important;
  color: var(--white-text-color) !important;
}

.btn-warning::after {
  display: none !important;
}

.btn-red,
.btn-red:hover,
.btn-red:focus {
  border-color: var(--theme-body-bg-red-color) !important;
  background-color: var(--theme-body-bg-red-color) !important;
  color: var(--white-text-color) !important;
}

.btn-red::after {
  display: none !important;
}

.btn-icon {
  min-width: 45px;
  height: 45px;
  padding: 0;
  border-radius: 50% !important;
}

.btn-icon > * {
  width: 18px;
  height: 18px;
}

.btn-icon.btn-sm {
  min-width: 35px;
  height: 35px;
  display: grid;
  place-items: center;
}

.btn-icon.btn-sm > * {
  font-size: 12px;
  width: 12px;
  height: 12px;
}

.qty-plus-minus-btn {
  background-color: var(--theme-body-bg-blue-color);
  color: var(--white-text-color);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 35px;
  overflow: hidden;
  min-width: 80px;
  width: 80px;
}

.qty-plus-minus-btn button {
  font-size: 12px;
  background-color: transparent;
  color: var(--white-text-color);
  border: 0;
  padding: 12px 5px;
  line-height: 1;
}

.qty-plus-minus-btn span {
  font-size: 12px;
  color: var(--white-text-color) !important;
  font-weight: var(--fw-medium);
  line-height: 1;
}

.bg-light {
  background-color: var(--theme-body-bg-blue-light-color) !important;
}

button[disabled] {
  opacity: 0.5 !important;
  pointer-events: none;
  cursor: not-allowed;
}

@media (max-width: 767px) {
  .row {
    margin-left: -20px;
    margin-right: -20px;
  }
  .row > * {
    padding-left: 20px;
    padding-right: 20px;
  }
  .row.g-0 {
    margin: 0;
  }
  .row.g-0 > * {
    padding: 0;
  }
  .row-mob-gap {
    margin-left: -12px;
    margin-right: -12px;
  }
  .row-mob-gap > * {
    padding-left: 12px;
    padding-right: 12px;
  }
  .container,
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.scrollbar-sm::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar-sm::-webkit-scrollbar-track {
  background: var(--border-light-color);
  border-radius: 35px;
}

.scrollbar-sm::-webkit-scrollbar-thumb {
  background: var(--theme-body-bg-blue-color);
  border-radius: 35px;
}

.scrollbar-sm::-webkit-scrollbar-thumb:hover {
  background: var(--theme-body-bg-blue-dark-color);
}

.scrollbar-gray::-webkit-scrollbar-thumb {
  background: rgba(26, 23, 27, 0.23);
}
