@import "./meta.css";
@import "./header-and-footer.css";

.hero-banner .swiper-slide {
  padding: 60px 0;
  display: flex;
  align-items: center;
  background-color: var(--theme-body-bg-blue-light-color);
  overflow: hidden;
}

.hero-banner .swiper-slide .row {
  align-items: center;
}

.hero-banner .hero-banner-img {
  width: 500px;
  height: 500px;
  object-fit: contain;
  object-position: center;
}

.hero-banner .hero-banner-content h2 span {
  color: var(--theme-body-bg-blue-color);
}

.hero-banner :is(.swiper-button-prev, .swiper-button-next) {
  color: var(--black-text-color);
  border: 2px solid var(--theme-body-bg-blue-color);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  transition: all 0.22s ease-in-out;
}

.hero-banner .swiper-button-prev {
  left: 25px;
}

.hero-banner .swiper-button-next {
  right: 25px;
}

.hero-banner :is(.swiper-button-prev, .swiper-button-next):hover {
  border: 2px solid var(--theme-body-bg-blue-color);
  background-color: var(--theme-body-bg-blue-color);
  color: var(--white-text-color);
}

.hero-banner :is(.swiper-button-prev, .swiper-button-next)::after {
  color: inherit;
  font-size: 14px;
  font-weight: var(--fw-bold);
}

@media (max-width: 991px) {
  .hero-banner .swiper-slide {
    padding: 40px 0;
  }

  .hero-banner :is(.swiper-button-prev, .swiper-button-next) {
    display: none;
  }

  .hero-banner .hero-banner-img {
    width: 250px;
    height: 250px;
  }
}

.hero-banner .swiper-slide .fade-left {
  transform: translate3d(-250px, 0, 0) !important;
  transition: all 1s !important;
  transition-delay: 0.1s !important;
}

.hero-banner .swiper-slide-active .fade-left {
  transform: translate3d(0, 0, 0) !important;
}

/* .hero-banner .swiper-slide .hero-banner-content {
  transform: translate3d(250px, 0, 0) !important;
  transition: all 1s !important;
  transition-delay: 0.1s !important;
} */

/* .hero-banner .swiper-slide-active .hero-banner-content {
  transform: translate3d(0, 0, 0) !important;
} */

.hero-banner .swiper-pagination-bullet {
  width: 40px;
  height: 8px;
  background-color: var(--theme-body-bg-blue-color);
  border-radius: 0px;
  transition: all 0.25s linear;
  opacity: 0.4;
}

.hero-banner .swiper-pagination-bullet-active {
  height: 12px;
  background-color: var(--theme-body-bg-blue-color) !important;
  opacity: 1;
}

/* card - product card */

.product-card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

.product-card-1 {
  background-color: var(--theme-body-bg-white-color);
  border: 1.5px solid var(--border-light-color);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.05);
  height: 100%;
  border-radius: 32px !important;
  transition: all 0.22s ease-in-out;
  cursor: pointer;
}

.product-card-1:hover {
  background-color: var(--theme-body-bg-blue-dark-color);
}

.product-card-1 .card-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-card-1 .card-img img {
  width: 230px;
  height: 280px;
  object-fit: contain;
  object-position: center;
  padding: 10px 0;
  mix-blend-mode: multiply;
  transition: all 0.18s ease-in-out;
}

.product-card-1:hover .card-img img {
  transform: scale(1.1);
}

.product-card-1 .btn:hover {
  transform: scale(1.1);
}

.product-card-1 .card-body {
  padding: 10px 15px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.product-card-1 .card-body .card-title {
  font-size: 16px;
  font-weight: var(--fw-medium);
  margin-bottom: 5px;
}

.product-card-1 .card-body .card-price {
  color: var(--theme-body-bg-blue-color);
  font-size: 16px;
  font-weight: var(--fw-bold);
  white-space: nowrap;
}

@media (max-width: 991px) {
  .product-card-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 767px) {
  .product-card-grid {
    grid-template-columns: 1fr;
  }
}

/* product-single */

.product-single {
  padding: 40px 0;
}

.product-single .product-single-image {
  background-color: var(--theme-body-bg-blue-light-color);
  display: grid;
  place-items: center;
  border-radius: 15px;
  min-height: 550px;
  position: relative;
}

.product-single .product-single-image > img {
  width: 320px;
  height: 320px;
  mix-blend-mode: multiply;
}

.product-single .product-single-content p span {
  color: var(--light-text-color);
  font-weight: var(--fw-medium);
}

.product-single .product-single-content p span {
  color: var(--light-text-color);
  font-weight: var(--fw-medium);
}

.product-single .product-single-content p.price {
  color: var(--theme-body-bg-blue-color);
  font-size: 22px;
  font-weight: var(--fw-bold);
}

.product-single .additional-information-table :is(th, td) {
  font-size: 18px;
  padding: 15px;
}

.product-single .product-single-image .product-single-image-sm {
  display: flex;
  gap: 10px;
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.product-single .product-single-image .product-single-image-sm div {
  width: 80px;
  height: 80px;
  border: 1px solid var(--border-light-color);
  background-color: var(--theme-body-bg-white-color);
  border-radius: 8px;
  display: grid;
  place-items: center;
  cursor: pointer;
  overflow: hidden;
}

.product-single .product-single-image .product-single-image-sm div img {
  width: 65px;
  height: 65px;
  mix-blend-mode: multiply;
}

@media (max-width: 992px) {
  .product-single .product-single-image {
    min-height: 450px;
  }

  .product-single .product-single-image > img {
    width: 250px;
    height: 250px;
  }

  .product-single .product-single-content p.price {
    font-size: 19px;
  }
}

/* inner page */

/* inner page heading */

.inner-page-heading {
  padding: 60px 0;
  background-color: var(--theme-body-bg-blue-light-color);
}

.inner-page-heading h1 {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .inner-page-heading {
    padding: 30px 0;
  }
}

/* about card 1 */

.card-about-1 {
  padding: 30px;
  background-color: var(--theme-body-bg-white-color);
  border-radius: 25px;
  border: 0;
}

.card-about-1 img {
  width: 90px;
  height: 90px;
  margin-bottom: 1.5rem;
  color: var(--theme-body-bg-blue-color);
  fill: var(--theme-body-bg-blue-color);
}

.card-about-1 h3 {
  margin-bottom: 1rem;
}

/* pagination */

.MuiPagination-root button.Mui-selected {
  background-color: var(--theme-body-bg-blue-color);
  color: var(--white-text-color);
}

/* card contact */

.card-contact {
  padding: 30px;
  background-color: var(--theme-body-bg-blue-light-color);
  border-radius: 25px;
  border: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.card-contact > i {
  font-size: 26px;
  color: var(--theme-body-bg-blue-color);
  margin-bottom: 1.2rem;
}

.card-contact p {
  margin-bottom: 3px;
}

.card-contact p :is(a, p) {
  font-size: 18px;
  font-weight: var(--fw-medium);
  text-decoration: none;
  color: var(--black-text-color);
}

.card-contact p a:hover {
  color: var(--theme-body-bg-blue-color);
}

/* form */

.form-group label {
  font-size: 18px;
  font-weight: var(--fw-medium);
  margin-bottom: 10px;
}

.form-control {
  padding: 10px 15px;
  border-radius: 8px;
  background-color: var(--theme-body-bg-light-color);
  border: 1px solid var(--theme-body-bg-light-dark-color);
}

.select-wrapper .form-control {
  background-color: transparent !important;
  border: 0 !important;
  appearance: auto;
}

.select-wrapper {
  border-radius: 8px;
  background-color: var(--theme-body-bg-light-color);
  border: 1px solid var(--theme-body-bg-light-dark-color);
  padding-right: 10px;
}

.form-control:focus {
  border-color: var(--theme-body-bg-blue-color) !important;
  background-color: var(--theme-body-bg-light-dark-color) !important;
  box-shadow: 0 0 0 0.25rem rgb(0, 141, 210, 0.25);
}

input[type="checkbox"] {
  border-radius: 0px !important;
  cursor: pointer;
  accent-color: var(--theme-body-bg-blue-dark-color);
  height: 14px !important;
  width: 14px !important;
  outline: none !important;
  border: 1px solid var(--border-light-color);
  font-size: 20px;
  transform: scale(1.3);
  margin-right: 8px;
}

.error-message {
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
  color: var(--theme-body-bg-red-color);
}

[aria-invalid="true"] {
  border-color: var(--theme-body-bg-red-color) !important;
}

[aria-invalid="true"]:focus {
  border-color: var(--theme-body-bg-red-color) !important;
  box-shadow: 0 0 0 0.25rem rgb(229, 18, 25, 0.25);
}

.password-input-wrapper {
  position: relative;
}

.password-input-wrapper .icon {
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  display: flex;
  align-items: center;
}

.password-input-wrapper .icon i {
  font-size: 18px;
  cursor: pointer;
}

/* user wrapper */

.user-wrpaper {
  background-color: var(--theme-body-bg-blue-light-color);
  min-height: calc(100vh - var(--header-height));
  padding: 60px 0;
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .user-wrpaper {
    padding: 30px 0;
  }
}

/* users */

.user-card-1 {
  border-radius: 20px;
  background-color: var(--theme-body-bg-white-color);
  border: 0;
  padding: 30px;
}

.user-card-1 h1 {
  font-size: 32px;
  text-align: center;
  margin-bottom: 1rem;
}

.user-card-1 a {
  font-weight: var(--fw-medium);
}

/* placeholder */

.document-placeholder {
  border: 2px solid var(--border-light-color);
  display: inline-block;
  border-radius: 12px;
  overflow: hidden;
  max-width: 250px;
  height: 150px;
  position: relative;
}

.document-placeholder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.document-placeholder .btn.close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 99;
}

.user-img-placeholder {
  border: 2px solid var(--border-light-color);
  display: inline-block;
  border-radius: 12px;
  overflow: hidden;
  max-width: 120px;
  height: 120px;
  position: relative;
}

.user-img-placeholder img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.user-img-placeholder .btn.close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 99;
}

.grid.cart-itme-summary {
  display: flex;
  flex-direction: column;
}

.grid.cart-itme-summary .grid-item:first-child {
  border-top: 1px solid var(--border-light-color);
}

.grid.cart-itme-summary .grid-item {
  border-bottom: 1px solid var(--border-light-color);
  padding: 1rem 0;
}

.cart-itme-summary-card {
  font-size: 14px;
  display: grid;
  grid-template-columns: 100px 3fr;
}

.cart-itme-summary-card .card-img {
  width: 90px;
  height: 90px;
  border: 1px solid var(--border-light-color);
  border-radius: 10px;
  display: grid;
  place-items: center;
}

.cart-itme-summary-card .card-img img {
  width: 70px;
  height: 70px;
  object-fit: contain;
  object-position: center;
}

.cart-itme-summary-card p {
  margin-bottom: 0;
}

.cart-itme-summary-card p:last-child {
  margin-bottom: 0;
}

.summary-total {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.summary-total li {
  border-bottom: 1px solid var(--border-light-color);
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.payment-information-box {
  border-radius: 15px;
  background-color: var(--theme-body-bg-white-color);
  box-shadow: 0 0 12px rgb(0, 0, 0, 0.05);
  padding: 30px;
}

.card-order {
  font-weight: var(--fw-medium);
}

.card-order .card-head {
  background-color: #f5f5f5;
  font-size: 14px;
  padding: 15px 25px;
}

.card-order .card-head ul {
  list-style-type: none;
  display: flex;
  gap: 2rem;
  margin: 0;
  padding: 0;
}

.card-order .card-head ul li {
  display: flex;
  flex-direction: column;
}

.card-order .card-body {
  padding: 25px;
}

.card-order .card-body .order-summary {
  min-width: 250px;
}

.card-order .card-body .order-summary :is(th, td) {
  padding: 5px 0;
}

.card-order .card-body address {
  font-size: 16px;
  max-width: 200px;
}

.card-order .card-body + .card-body {
  border-top: 1px solid var(--border-light-color);
}

/* form box */

.form-box {
  background-color: var(--theme-body-bg-white-color);
  padding: 30px 60px;
  border-radius: 1rem !important;
  min-width: 550px;
}

.form-box-icon {
  width: 80px;
  height: 80px;
  border: 2px solid var(--theme-body-bg-light-dark-color);
  font-size: 35px;
  color: var(--theme-body-bg-light-dark-color);
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.form-box-icon .spinner-border {
  width: 80px;
  height: 80px;
  border-width: 3px;
  border-right: 0;
}

.form-box-icon.unset {
  border: 0;
}

.form-box_error .form-box-icon {
  border-color: var(--theme-body-bg-red-color);
  color: var(--theme-body-bg-red-color);
}

.form-box_success .form-box-icon {
  border-color: var(--theme-body-bg-green-color);
  color: var(--theme-body-bg-green-color);
}

.form-box_warning .form-box-icon {
  border-color: var(--theme-body-bg-yellow-color);
  color: var(--theme-body-bg-yellow-color);
}
